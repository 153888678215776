import { useStaticQuery, graphql } from "gatsby";

export const useAboutImage = () => {
	const { image } = useStaticQuery(
		graphql`
			query AboutUsImage {
				image: allFile(
					sort: { modifiedTime: DESC }
					filter: {
						extension: { regex: "/(jpg)|(jpeg)|(png)/" }
						sourceInstanceName: { eq: "brandingIMGs" }
						name: { in: "about-us" }
					}
				) {
					edges {
						node {
							id
							relativeDirectory
							childImageSharp {
								gatsbyImageData(
									placeholder: BLURRED
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
			}
		`
	);
	return image?.edges[0]?.node;
};
