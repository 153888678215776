import { useStaticQuery, graphql } from "gatsby";

export const useFooter = () => {
	const { allFooter } = useStaticQuery(
		graphql`
			query FooterData {
				allFooter {
					nodes {
						data
					}
				}
			}
		`
	);
	return allFooter?.nodes[0].data;
};
