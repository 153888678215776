import { useStaticQuery, graphql } from "gatsby";

export const useNavLinks = () => {
	const { site } = useStaticQuery(
		graphql`
			query SiteNavData {
				site {
					siteMetadata {
						NavLinks {
							NavLinks {
								href
								name
								subMenu {
									href
									name
								}
							}
						}
					}
				}
			}
		`
	);
	return site?.siteMetadata?.NavLinks;
};
