import { useStaticQuery, graphql } from "gatsby";

export const useSidebar = () => {
	console.log("useSidebar");
	const { allSidebar } = useStaticQuery(
		graphql`
			query Sidebar {
				allSidebar {
					nodes {
						days {
							closed
							day
							hours
						}
						cta {
							subheading
							heading
						}
						quicklinks {
							link
							text
						}
					}
				}
			}
		`
	);
	return allSidebar?.nodes[0];
};
