import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ title, area, phone_number, children, ...props }) => {
	return (
		<>
			<Header title={title} area={area} phone_number={phone_number} />
			{children}
			<Footer title={title} area={area} phone_number={phone_number} />
		</>
	);
};

export default Layout;
