import { useStaticQuery, graphql } from "gatsby";

export const useLogo = () => {
	const { logo } = useStaticQuery(
		graphql`
			query SiteLogo {
				logo: allFile(
					sort: { modifiedTime: DESC }
					filter: {
						extension: { regex: "/(jpg)|(jpeg)|(png)/" }
						sourceInstanceName: { eq: "brandingIMGs" }
						name: { in: "logo" }
					}
				) {
					edges {
						node {
							id
							relativeDirectory
							childImageSharp {
								gatsbyImageData(
									placeholder: BLURRED
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
			}
		`
	);
	return logo?.edges[0]?.node;
};
