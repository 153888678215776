import React from "react";
import { Link } from "gatsby";
import { useSidebar } from "../../hooks/defaults/Use-Sidebar";
import { useReplaceDefaults } from "../../utils/use-Replace-Defaults";

const WhoWeAre = () => {
	console.log("WhoWeAre");
	const { quicklinks, cta, days } = useSidebar();
	return (
		<>
			<h1 className="text-blue-500 underline decoration-double mb-4 text-2xl">
				Quick Links
			</h1>
			<div className=" bg-slate-300 outline outline-offset-[6px] hover:outline-offset-0 outline-blue-500/40 hover:outline-blue-500/70 duration-150  overflow-hidden rounded-lg py-6 px-4">
				<ol className="pl-4 list-disc  ">
					{quicklinks.map((link, index) => {
						return (
							<li
								id={index}
								className="text-slate-800 hover:text-blue-500  tracking-wide "
							>
								<Link href={link.link}>
									{" "}
									{useReplaceDefaults({
										text: link.text,
									})}
								</Link>
							</li>
						);
					})}
				</ol>
			</div>

			<div className="mt-10  text-center bg-blue-900 outline outline-offset-[6px] hover:outline-offset-0 outline-slate-500/40 hover:outline-slate-500/70 duration-150  overflow-hidden rounded-lg py-6 ">
				<div className="   flex  flex-col divide-y ">
					<h1 className="text-white underline mb-4 text-xl">Working Hours</h1>
					{days.map((link, index) => {
						return (
							<div index={index}>
								{" "}
								<div className="mt-2 flex-wrap text-white hover:underline  tracking-wide ">
									<div>{link.day}</div>
								</div>
								<div className=" mb-2 flex-wrap text-white hover:underline  tracking-wide ">
									<div>{link.hours}</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<div className="mt-10  bg-blue-900 outline outline-offset-[6px] hover:outline-offset-0 outline-slate-500/40 hover:outline-slate-500/70 duration-150  overflow-hidden rounded-lg py-6 ">
				<div className=" p-4  flex  flex-col ">
					<h1 className="text-white mb-4 text-xl">
						{useReplaceDefaults({
							text: cta.heading,
						})}
					</h1>

					<div>
						<p className="text-white">
							{useReplaceDefaults({
								text: cta.subheading,
							})}
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default WhoWeAre;
