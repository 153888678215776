import * as React from "react";
import Header from "../components/includes/defaults/header";
import Cta from "../components/CTA/CTAMain";

import Footer from "../components/includes/defaults/footer";
import QuickInfo from "../components/includes/quickInfo";
import Seo from "../components/seo";
import WhereWeAre from "../components/includes/where_we_are";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useAboutImage } from "../hooks/images/use-About-Image";
import { useAboutPage } from "../hooks/defaultsPages/Use-About-Page";
import { useSiteMetadata } from "../hooks/defaults/Use-SiteMetadata";
import { useReplaceDefaults } from "../utils/use-Replace-Defaults";
import Layout from "../components/Defaults/Layout";

const AboutPage = () => {
	const {
		googleMap,
		address: { addressRegion },
	} = useSiteMetadata();
	const AboutImage = useAboutImage();
	const AboutData = useAboutPage();
	const AboutDataData = AboutData?.data;
	const FirstAboutDataFirst = AboutDataData[0];
	const ResAboutData = AboutDataData.slice(1, AboutDataData.length);

	return (
		<Layout>
			<Cta background={true} />
			<section className="mt-10 mx-auto  max-w-screen-xl items-center mb-20 justify-between  ">
				<div className="grid grid-cols-1 md:grid-cols-12 gap-8">
					<div className="col-span-1 md:col-span-9">
						<div className="md:p-0 p-4">
							<div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 pb-6">
								<div className="col-span-1 text-black md:col-span-1">
									<h2 className="text-2xl text-blue-500  mb-4 pb-2">
										{useReplaceDefaults({
											text: AboutData?.heading,
										})}
									</h2>
									<p>
										{useReplaceDefaults({
											text: FirstAboutDataFirst,
										})}
									</p>
								</div>
								<div className="col-span-1 text-black md:col-span-1">
									<GatsbyImage
										image={getImage(AboutImage.childImageSharp)}
										className="mx-auto rounded-2xl border-2 border-slate-800"
										placeholder="blurred"
										alt="A1 Roofing Surrey"
										style={{ height: "300px", width: "100%" }}
									/>
								</div>
							</div>
							<div className="mt-4 ">
								{ResAboutData.map((item, index) => {
									return (
										<p key={index} className="text-black  tracking-wide">
											{useReplaceDefaults({ text: item })}
										</p>
									);
								})}
							</div>
						</div>
					</div>
					<div className="col-span-1 md:col-span-3 p-4 md:p-0 ">
						<QuickInfo />
					</div>
				</div>
				<WhereWeAre gMap={googleMap ? googleMap : ""} area={addressRegion} />
			</section>
		</Layout>
	);
};
export const Head = () => <Seo title="About Us" />;

export default AboutPage;
