import React from "react";
import LazyLoad from "react-lazy-load";
const WhoWeAre = ({ heading, lat, long, area, gMap }) => {
	var source = gMap
		? gMap
		: lat && long
		? `https://maps.google.com/maps?q=${lat},${long}&t=&z=9&ie=UTF8&iwloc=&output=embed`
		: `https://maps.google.com/maps?q=${String(area).replaceAll(
				" ",
				"%20"
		  )}&t=&z=10&ie=UTF8&iwloc=&output=embed`;

	return (
		<>
			<section>
				<div className="mt-20 mx-auto max-w-screen-xl items-center justify-between px-4">
					<h3 className="text-3xl text-blue-500 text-center pb-4  border-b-2">
						{heading ? heading : "Where We Are"}
					</h3>
				</div>
				<LazyLoad height={"100%"}>
					<iframe
						className="mx-auto outline  outline-blue-500/30 hover:outline-blue-500/60 duration-300"
						loading="lazy"
						width="100%"
						height="400"
						id="gmap_canvas"
						src={source}
						title={heading ? heading : "Where We Are"}
					></iframe>
				</LazyLoad>
			</section>
		</>
	);
};

export default WhoWeAre;
