import { useStaticQuery, graphql } from "gatsby";

export const useAboutPage = () => {
	const { allAbout } = useStaticQuery(
		graphql`
			query aboutData {
				allAbout {
					nodes {
						heading
						data
					}
				}
			}
		`
	);
	return allAbout?.nodes[0];
};
